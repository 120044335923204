import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["named_adults"];
  static values = { minCount: { type: Number, default: 1 } };

  connect() {
    let isNamedAdultsStep =
      document.getElementById("booking_form").dataset.formTarget ==
      "named_adults";

    if (!isNamedAdultsStep) {
      this.inputs().forEach((input) => {
        if (["checkbox", "radio", "text"].includes(input.type)) {
          input.dataset["action"] =
            input.dataset["action"] + " input->disable-button#checkValue";
        } else {
          input.dataset["action"] =
            input.dataset["action"] + " change->disable-button#checkValue";
        }
      });

      this.checkValue();

      this.templates().forEach((input) => {
        input.dataset["action"] =
          input.dataset["action"] + " change->disable-button#checkValue";
      });
    }

    if (isNamedAdultsStep) {
      this.checkNamedAdultsValues();
    }

    if (this.addButton() != undefined) {
      //add event to check empty fields and disable button
      let button = this.addButton();

      button.dataset["action"] =
        button.dataset["action"] + " click->disable-button#checkValue";
    }
  }

  checkValue(event) {
    let isDisabled = false;
    let stop = false;

    if (this.minCountValue > 0) {
      this.inputs().forEach((input) => {
        if (stop) {
          return;
        }

        if (input.type == "checkbox") {
          // This is a workaround for the guest pass checkbox as all checkboxes use this to change the continue button
          // This should be refactored, so that this entire controller is not required
          if (input.dataset["checkValue"] == "false") {
            return;
          }

          if (input.checked) {
            isDisabled = false;

            stop = true;
          } else {
            console.log("check 1");
            isDisabled = true;
          }
        } else if (input.type == "radio") {
          if (input.checked) {
            isDisabled = false;

            stop = true;
          } else {
            console.log("check 2");
            isDisabled = true;
          }
        } else if (["select-one", "text"].includes(input.type)) {
          if (input.value.length <= 0) {
            console.log("check 3");
            isDisabled = true;

            stop = true;
          } else {
            isDisabled = false;
          }
        } else if (
          document.getElementById("summaryStep") !== null &&
          document.getElementById("payment_method_token") == null
        ) {
          isDisabled = false;

          stop = true;
        } else {
          console.log("check 4");
          isDisabled = true;
        }
      });
    }

    isDisabled
      ? this.submitButton().setAttribute("disabled", "disabled")
      : this.submitButton().removeAttribute("disabled");
  }

  checkNamedAdultsValues(event) {
    let dropOffFilled = false;
    let pickUpFilled = false;
    let passphraseFilled = false;

    let drop_off_inputs = this.element.querySelectorAll(".drop_off_users");
    drop_off_inputs.forEach((input) => {
      if (input.checked) {
        dropOffFilled = true;
      }
    });

    let pick_up_inputs = this.element.querySelectorAll(".pick_up_users");
    pick_up_inputs.forEach((input) => {
      if (input.checked) {
        pickUpFilled = true;
      }
    });

    let passphrase_input =
      this.element.querySelectorAll("input[type='text']")[0];
    if (passphrase_input.value.length > 0) {
      passphraseFilled = true;
    }

    if (dropOffFilled && pickUpFilled && passphraseFilled) {
      this.submitButton().removeAttribute("disabled");
    } else {
      this.submitButton().setAttribute("disabled", "disabled");
    }
  }

  inputs() {
    return this.element.querySelectorAll(
      "select, input[type='text'], input[type='checkbox'], input[type='radio'], input[type='hidden']",
    );
  }

  submitButton() {
    return document.getElementById("submit");
  }

  addButton() {
    return document.querySelector("[data-id='add_nested']");
  }

  templates() {
    let template = this.element.querySelector("template");
    return template == undefined
      ? []
      : this.element
          .querySelector("template")
          .content.querySelectorAll("select, input[type='text']");
  }
}
